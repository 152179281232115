* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Encode Sans Expanded", sans-serif;
}

.vertical-timeline-element--work .vertical-timeline-element-content {
    border-top: 3px solid #01bf71;
}
